:root {
  --primary-color: #2cbf96;
  --secondary-color: #13b184;
  --primary-orange: #f29829;
  --black: #000000;
  --white: #ffffff;
  --gray: #efefef;
  --gray-2: #757575;

  --facebook-color: #4267b2;
  --google-color: #db4437;
  --twitter-color: #1da1f2;
  --insta-color: #e1306c;
}
@import './themes-vars';
@import './plugins/plugins';

.scrollbar-container .MuiList-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.scrollbar-container .MuiButtonBase-root {
  margin-top: 6px !important;
}

/* breadcrumb */
.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

/* card */

.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.responsive-chart {
  width: 33.33%;
  min-width: 250px;
}

.chart-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chat-icon {
  position: fixed !important;
  right: 16px !important;
  bottom: 16px !important;
  background: #2cbf96 !important;
  color: white !important;
}

.main-contener {
  height: calc(100vh - 144px);
}

@media only screen and (min-width: 600px) {
  .res-toolbar {
    min-height: 70px !important;
  }
}
@media only screen and (max-width: 1124px) {
  .responsive-chart {
    width: 50%;
  }
}
@media only screen and (max-width: 550px) {
  .responsive-chart {
    width: 100%;
  }
}

.chart-contener {
  width: 100%;
}

.chart-data > div {
  width: 100% !important;
}

// home form

.signup-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type='text'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.form-group input {
  width: 100%;
  height: 40px;
}

button[type='submit'] {
  background-color: #13b184;
  color: white;
  padding: 7px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button1[type='button'] {
  background-color: #13b184;
  color: white;
  padding: 7px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.button2[type='button'] {
  background-color: white;
  color: #13b184;
  padding: 7px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button[type='submit']:disabled {
  background-color: #cccccc;
  cursor: default;
}

.dashboardStyle {
  background-image: url('../images/multi-wave.svg');
  background-size: cover;
  height: 82vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.session-table thead th{
  padding: 10px;
}

.session-table tbody td{
  padding: 0px 4px;
}

.MuiToggleButtonGroup-root {
  gap: 1px;
}
.bg-blue-500{
  --tw-bg-opacity: 1;
   background-color: var(--primary-color);
}